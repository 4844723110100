import React, { useEffect } from 'react'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { FormLayout } from '@/components/template/form-layout'
import { Layout } from '@/components/template/layout'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input } from '@/components/atoms/input'
import { Button } from '@/components/atoms/button'
import { css } from '@emotion/react'
import { horizontalSpacing, pcScreenStyle } from '@/styles/common'
import { useAppDispatch } from '@/app/hooks'
import { setCheckoutReservations } from '@/features/checkout/checkout-reservation-slice'
import { getStayReservations } from '@/features/reservation/stay-reservation-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { MessageType, toastAction } from '@/features/notification/toast-slice'
import { clearCheckoutResultHistories } from '@/features/checkout/checkout-data-slice'

export const CheckoutSearch: React.FC = () => {
  const { t } = useTranslation()
  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { handleSubmit, watch, register } = useForm<{ telephone: string }>({
    mode: 'all',
  })

  const watchTelephone = watch('telephone')

  const onSubmit: SubmitHandler<{ telephone: string }> = async submitData => {
    dispatch(setCheckoutReservations(null))

    const stayReservations = await dispatch(getStayReservations({ hotelId, telephone: submitData.telephone })).unwrap()

    if (!stayReservations?.length) {
      return dispatch(toastAction.setMessage(MessageType.Error, t('Registration information not found. Please try again.')))
    }

    if (stayReservations.some(reservation => reservation.frontSupported)) {
      dispatch(setCheckoutReservations(stayReservations))
      return navigate({ pathname: '/checkout/thanks', search: `hotelId=${hotelId}` })
    }
    return navigate({ pathname: '/checkout/select-reservation', search: `hotelId=${hotelId}&telephone=${submitData.telephone}` })
  }

  useEffect(() => {
    dispatch(clearCheckoutResultHistories())
  })

  return (
    <Layout>
      <NavigateHeader title={t('Check-out')} style={{ marginBottom: '1rem' }} />
      <form onSubmit={handleSubmit(onSubmit)} css={pcScreenStyle}>
        <FormLayout>
          <h3 css={labelStyle}>{t('Phone number of your reservation')}</h3>
          <Input radiusStyle {...register('telephone')} placeholder="08012345678" type="tel" />
        </FormLayout>

        <Button disabled={isEmpty(watchTelephone)} buttonCss={[horizontalSpacing, buttonStyle]} text={t('Next')} type="submit" />
      </form>
    </Layout>
  )
}

const labelStyle = css({
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: '1.5rem',
})

const buttonStyle = css({
  marginTop: '2rem',
})
